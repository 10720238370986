import React from "react";
import { css } from "@emotion/react";
import { Div } from "@components/Markup";
import VideoBackground from "@components/HomepageBackground";

const HomepageVideo = () => {
  return (
    <React.Fragment>
      <VideoBackground />
      <Div
        css={css`
          background: rgba(0, 0, 0, 0.65);
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          opacity: 0.45;
          pointer-events: none;
        `}
      />
    </React.Fragment>
  );
};

export default HomepageVideo;
