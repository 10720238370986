import React from "react";
import { css } from "@emotion/react";
import { Div } from "./Markup";
import { gsap } from "gsap/all";
import { IconPng } from "@components/Icon";
import { Title } from "@components/Typography";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SplitText from "../plugins/SplitText";
gsap.registerPlugin(SplitText);

/*

<Div
        pt="xs"
        pb="xs"
        flex
        jc="space-between"
        ai="center"
        css={css`
          padding-right: 90px;
          span {
            font-family: "noah-regular";
          }
        `}
      >
        <Text textStyle="label">Unsere Vision</Text>
      </Div>
      */

const PrevArrow = props => {
  const { onClick } = props;
  return (
    <Div
      flex
      ai="center"
      jc="center"
      className="hh-arrow hh-arrow--prev"
      css={css`
        height: 40px;
        width: 40px;
        cursor: pointer;
      `}
      onClick={onClick}
    >
      <IconPng name="arrowLeftWhite" />
    </Div>
  );
};

const NextArrow = props => {
  const { onClick } = props;
  return (
    <Div
      className="hh-arrow hh-arrow--next"
      flex
      ai="center"
      jc="center"
      css={css`
        height: 40px;
        width: 40px;
        cursor: pointer;
      `}
      onClick={onClick}
    >
      <IconPng name="arrowRightWhite" />
    </Div>
  );
};

const defaultSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  touchThreshold: 13,
  centerPadding: 0,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const items = [
  {
    text: "ÄSTHETIK<br />vereint Formen und Farben zu Anziehungskraft",
    key: "one",
  },
  { text: "KREATIVITÄT<br />denkt über bestehende Grenzen hinaus", key: "two" },
  {
    text:
      "IDENTITÄT<br />formt unverwechselbare Gebäude",
    key: "three",
  },
  {
    text:
      "TEAMGEIST<br />ermöglicht gemeinsam Ziele zu erreichen",
    key: "four",
  },
  {
    text:
      "VERTRAUEN<br />ist Überzeugung von der Zuverlässigkeit",
    key: "five",
  },
];

const SliderItem = ({ text }) => (
  <Div
    css={css`
      height: 100px;
      display: flex;
      align-items: flex-start;

      @media (min-width: 768px) {
        height: 150px;
      }
    `}
  >
    <Title size="md" dangerouslySetInnerHTML={{ __html: text }} />
  </Div>
);

const HomepageSlider = () => {
  return (
    <Div
      css={css`
        height: 100%;
        position: relative;
      `}
    >
      <Slider
        {...defaultSettings}
        css={css`
          /* border: 1px solid red; */

          .slick-dots {
          }

          .slick-track {
            display: flex;
          }

          .slick-slide {
            opacity: 1;
            transition: opacity 0.1s;
            outline: none !important;
          }

          .hh-arrow {
            position: absolute;
            /* top: 50%;
            transform: translate3d(0, -50%, 0); */
            bottom: -30px;
            transition: 0.15s all;
            &:hover {
              opacity: 0.5;
            }
            &.hh-arrow--prev {
              left: -10px;
            }

            &.hh-arrow--next {
              left: 30px;
            }
          }
        `}
      >
        {items.map(item => (
          <SliderItem key={item.key} {...item} />
        ))}
      </Slider>
    </Div>
  );
};

export default HomepageSlider;
